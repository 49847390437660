import React from "react";
import { Button } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Howl } from "howler";

import "./homepage-styles.css";

const go = new Howl({
  src: ["/audio/go.mp3"],
});

const stop = new Howl({
  src: ["/audio/stop.mp3"],
});

const countdown = new Howl({
  src: ["/audio/countdown.mp3"],
});

type MyState = {
  sportTimeInSeconds: number;
  recoveryTimeInSeconds: number;
  numberOfCycles: number;
  soundType: string;
  started: boolean;
  remainingSeconds: number;
  currentCycle: number;
  currentState: string;
};
type MyProps = {};

class SportTimerPage extends React.Component<MyProps, MyState> {
  state: MyState = {
    sportTimeInSeconds: 25,
    recoveryTimeInSeconds: 15,
    numberOfCycles: 6,
    soundType: "voice",
    started: false,
    remainingSeconds: 0,
    currentCycle: 0,
    currentState: "",
  };
  constructor(props: any) {
    super(props);
    this.onStart = this.onStart.bind(this);
  }
  async onStart() {
    countdown.play()
    this.setState({
      started: true,
      currentState: "GET READY",
      remainingSeconds: 4,
    });
    for (let j = 0; j < 4; j++) {
      await new Promise((r) => setTimeout(r, 1000));
      this.setState({
        remainingSeconds: 4 - j - 1,
      });
    }

    for (let i = 0; i < this.state.numberOfCycles; i++) {
      go.play();
      this.setState({
        currentCycle: i + 1,
        currentState: "SPORT",
        remainingSeconds: this.state.sportTimeInSeconds,
      });
      let remainingSport = this.state.sportTimeInSeconds * 1000
      const endTimeSport = new Date().getTime() + remainingSport
      while (remainingSport > 0) {
        await new Promise((r) => setTimeout(r, 100));
        remainingSport = endTimeSport - new Date().getTime()
        this.setState({
          remainingSeconds: Math.ceil(remainingSport / 1000),
        });
      }
      stop.play();
      this.setState({
        currentCycle: i + 1,
        currentState: "RECOVERY",
        remainingSeconds: this.state.recoveryTimeInSeconds,
      });
      let remainingRecovery = this.state.recoveryTimeInSeconds * 1000
      const endTimeRecovery = new Date().getTime() + remainingRecovery
      while (remainingRecovery > 0) {
        await new Promise((r) => setTimeout(r, 100));
        remainingRecovery = endTimeRecovery - new Date().getTime()
        this.setState({
          remainingSeconds: Math.ceil(remainingRecovery / 1000),
        });
      }
    }

    this.setState({ started: false });
  }

  render() {
    const form = (
      <div className="sport-timer-form">
        <label>
          Sport time (seconds):
          <input
            className="bp3-input .modifier"
            type="number"
            value={this.state.sportTimeInSeconds}
            onChange={(e) =>
              this.setState({ sportTimeInSeconds: parseInt(e.target.value) })
            }
          />
        </label>
        <label>
          Recovery time (seconds):
          <input
            className="bp3-input .modifier"
            type="number"
            value={this.state.recoveryTimeInSeconds}
            onChange={(e) =>
              this.setState({
                recoveryTimeInSeconds: parseInt(e.target.value),
              })
            }
          />
        </label>
        <label>
          Number of cycles:
          <input
            className="bp3-input .modifier"
            type="number"
            value={this.state.numberOfCycles}
            onChange={(e) =>
              this.setState({ numberOfCycles: parseInt(e.target.value) })
            }
          />
        </label>
        <Button
          className="sport-timer-form-button"
          onClick={this.onStart}
          text={"Start"}
          disabled={this.state.started}
        />
      </div>
    );
    const status = (
      <div className="sport-timer-status">
        <h1>{this.state.currentState}</h1>
        <h2>Cycle: {this.state.currentCycle}</h2>
        <h2>Remaining: {this.state.remainingSeconds}</h2>
      </div>
    );
    return (
      <div className="sport-timer-page">
        {this.state.started ? status : form}
      </div>
    );
  }
}

export default SportTimerPage;
