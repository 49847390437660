import React from "react";
import StaticPage from "../static-page-viewer/StaticPageViewer"
import "./homepage-styles.css";

const WhatsAWebAppPage = () => (
  <div className="homepage">
    <header className="homepage-header">
      <h1>What's a web-app ?</h1>
      <p>Let's start by discussing what people used before web-apps: static HTML pages.</p>
      <p>Here is an <a href="/static-html">old-school link</a> to a static page also hosted 
      from my flat under the same domain as this site.
      </p>
      <p>Here is the full code of the HTML page:</p>
      <StaticPage />
    </header>
  </div>
);

export default WhatsAWebAppPage;
