import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WhatsAWebbAppPage from "./components/pages/WhatsAWebApp";
import AventPage from "./components/pages/Avent";
import SportTimerPage from "./components/pages/SportTimer";
import HomePage from "./components/pages/HomePage";
import Trains from "./components/pages/Trains";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/whats-a-web-app" element={<WhatsAWebbAppPage/>} />
          <Route path="/avent" element={<AventPage/>} />
          <Route path="/sport-timer" element={<SportTimerPage/>} />
          <Route path="/trains" element={<Trains/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
