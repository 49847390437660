import React from "react";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import * as _ from "underscore";

import "./homepage-styles.css";
import { IconName, IconNames } from "@blueprintjs/icons";

const order = [
  16, 21, 22, 15, 18, 7, 9, 12, 20, 17, 5, 10, 11, 6, 3, 24, 4, 1, 14, 13, 23,
  8, 19, 2,
];

const dayToIcon: { [day: number]: IconName } = {
  1: IconNames.HEART,
  2: IconNames.SNOWFLAKE,
  3: IconNames.HOME,
  4: IconNames.TREE,
  5: IconNames.GLOBE,
  6: IconNames.LIGHTNING,
  7: IconNames.RAIN,
  8: IconNames.ROCKET_SLANT,
  9: IconNames.SHIELD,
  10: IconNames.SNOWFLAKE,
  11: IconNames.CROWN,
  12: IconNames.FLAME,
  13: IconNames.MOON,
  14: IconNames.MOUNTAIN,
  15: IconNames.TREE,
  16: IconNames.SNOWFLAKE,
  17: IconNames.HEART,
  18: IconNames.DRIVE_TIME,
  19: IconNames.CLOUD,
  20: IconNames.LOCK,
  21: IconNames.SNOWFLAKE,
  22: IconNames.TREE,
  23: IconNames.TRAIN,
  24: IconNames.HEART,
};

type Present = {
  text: string;
  day: number;
  image_base64: string;
};
type MyState = {
  data: Array<Present>;
  isOpen: boolean;
  selected: number;
  locked: boolean;
  password: string;
};
type MyProps = {};

class AventPage extends React.Component<MyProps, MyState> {
  state: MyState = {
    data: [],
    isOpen: false,
    selected: 0,
    locked: true,
    password: "",
  };
  constructor(props: any) {
    super(props);
    this.onPasswordClick = this.onPasswordClick.bind(this);
    this.onClick = this.onClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {}
  handleClose() {
    this.setState({ isOpen: false });
  }
  onClick(i: number) {
    return () => this.setState({ isOpen: true, selected: i });
  }
  onPasswordClick() {
    let date = new Date();
    const month = date.getMonth();
    let today = date.getDate();
    if (month !== 11) {
      today = 0;
    }
    fetch("https://ad-mu.com/api/day/" + today, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pwd: this.state.password }),
    })
      .then((resp) => {
        if (!resp.ok) {
          return;
        }
        resp.json().then((d) => {
          this.setState({ data: d });
        });
        this.setState({ locked: false });
      })
      .catch((err) => console.log(err));
  }
  render() {
    let date = new Date();
    const month = date.getMonth();
    let today = date.getDate();
    if (month !== 11) {
      today = 0;
    }

    const cells = order.map((i) => {
      return (
        <div className="avent-cell hover-target">
          {i <= today ? (
            <>
              <div className="hide-on-hover">{i}</div>
              <div className="show-on-hover">
                <Button
                  large={true}
                  onClick={this.onClick(i)}
                  text={i}
                  icon={dayToIcon[i]}
                />
              </div>
            </>
          ) : (
            i
          )}
        </div>
      );
    });
    const selectedData = _.filter(
      this.state.data,
      (day) => day.day === this.state.selected
    )[0];
    const avent = (
      <>
        <div className="avent-page">{cells}</div>
        <Dialog
          isOpen={this.state.isOpen}
          onClose={this.handleClose}
          title={this.state.selected}
          canOutsideClickClose={true}
          usePortal={true}
        >
          <div className={Classes.DIALOG_BODY}>
            <p className="avent-text">
              {selectedData ? selectedData.text : null}
            </p>
          </div>
        </Dialog>
      </>
    );
    return this.state.locked ? (
      <div className="password-page">
        <div className="password-form">
          <input
            className="bp3-input .modifier"
            type="text"
            placeholder="Password"
            dir="auto"
            onChange={(e) => this.setState({ password: e.target.value })}
          />
          <Button onClick={this.onPasswordClick} text={"Send"} />
        </div>
      </div>
    ) : (
      avent
    );
  }
}

export default AventPage;
