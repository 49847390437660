import React, { Component } from "react"; // we need this to make JSX compile
import { Button } from "@blueprintjs/core";

type StaticPageState = {
  isLoading: boolean;
  show: boolean;
  html: String;
};

class StaticPage extends Component<{}, StaticPageState> {
  async loadStaticHtml() {
    const response = await fetch("https://ad-mu.com/static-html/");
    const data = await response.text();
    this.setState({
      isLoading: false,
      html: data,
    });
  }

  componentWillMount() {
    this.setState({
      isLoading: true,
      html: "",
      show: false,
    });
    this.loadStaticHtml();
  }

  render() {
    return (
      <div>
        <Button
          text="Show code"
          active={this.state.show}
          loading={this.state.isLoading}
          onClick={() => this.setState({ show: !this.state.show })}
        />
        {this.state.show ? <code>{this.state.html}</code> : null}
      </div>
    );
  }
}

export default StaticPage;
