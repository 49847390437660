import React from "react";
import { Link } from "react-router-dom";
import "./homepage-styles.css";

const HomePage = () => (
  <div className="homepage">
    <header className="homepage-header">
      <p>This is a web-app hosted on a Raspberry Pi sitting in my flat.</p>
      <Link to="/whats-a-web-app">What's a web-app ?</Link>
      <br />
      <Link to="/sport-timer">Sport timer</Link>
      <br />
      <Link to="/trains">Trains timetable</Link>
    </header>
  </div>
);

export default HomePage;
